export const useColorFilter = (items?: CMSProduct[], colorFilter?: string) => {
    if (!colorFilter) return items;

    return items?.reduce((newItems, currentItem) => {
        if (!currentItem.options?.Color) return newItems;

        const { image, price } = currentItem.variants?.items?.find((variant) => colorFilter.includes(variant.selectedOptions.Color)) ?? {};
        const variants = currentItem.variants.items?.filter((variant) => colorFilter.includes(variant.selectedOptions.Color));

        newItems.push({
            ...currentItem,
            options: {
                ...currentItem.options,
                Color: currentItem.options?.Color.filter((color) => colorFilter.includes(color)),
            },
            price,
            variants: { items: variants, total: variants.length },
            image: image || '',
        });
        return newItems;
    }, [] as CMSProduct[]);
};
